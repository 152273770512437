export const BASEURL = "http://127.0.0.1:8000";
export const FETCHBLOGS = "blogs/blog/FETCHBLOGS";
export const ADDBLOG = "blogs/blog/ADDBLOG";
export const REMOVEBLOG = "blogs/blog/REMOVEBLOG";
export const DELETE_BLOG = "blogs/blog/DELETE";
export const FETCH_BLOG = "blogs/blog/FETCHBLOG";
export const EDDITBLOG = "blogs/blog/EDDITBLOG";
export const FETCH_PROFILE = "blogs/blog/PROFILE";
export const FETCH_PROFILES = "blogs/blog/PROFILES";
export const UPDATE_PROFILES = "blogs/blog/PROFILES_UPDATE";
export const DELETE_PROFILES = "blogs/blog/DELETE_PROFILE";
export const LOGOUT = "blogs/blog/LOGOUT";