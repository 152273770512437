import React from 'react'
import App from '../editor/Apptext'


function CreateBlog() {
  return (
   <div>
     <div>
      <App />
    </div>
   </div>
  )
}

export default CreateBlog