import img1 from "../Images/About_us1.png";
import img2 from "../Images/About_us2.png";
import img3 from "../Images/About_us3.png";
const About = () => {
  return (
    <div>
      <div className="row">
        <div className="column">
          <img src={img1} alt="A group of children smiling in a window" />
        </div>
        <div className="column">
          <h1>Our story</h1>
          <p>
            KatexPower is a renewable energy company that offers clean and
            sustainable energy services to off-grid and underserved communities.
            <br />
            <br />
            Our mission is to provide clean, affordable and reliable energy
            solutions that improve the quality of life for individuals and
            communities while reducing carbon emissions.
            <br />
            <br />
            We aim to empower people through access to clean energy, creating a
            more sustainable future for all. With years of experience in the
            industry, we have a proven track record of delivering high-quality
            solar installations and exceptional customer service.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="column">
          <h1>Why choose us</h1>
          <p>
            We are commited to providing affordable and sustainable energy
            solutions for homes and businesses. With years of experience in the
            industry, we have a proven track record of delivering high-quality
            installations and exceptional customer service and ensure our
            clients receive top-notch services and products that meet their
            energy needs.
          </p>
        </div>
        <div className="column">
          <img src={img2} alt="A builders helmet on top of a solar pannel" />
        </div>
      </div>

      <div className="row">
        <div className="column">
          <h1>Our solution</h1>
          <p>
            By using the latest technology and offering competitive pricing, we
            can help our clients save money while reducing their carbon
            footprint.
            <br />
            <br />
            Our commitment to providing access to clean and sustainable energy
            is reflected in our partnerships with leading renewable energy
            providers and our ongoing efforts to invest in innovative solutions
            that promote a more sustainable future for all.
            <br />
            <br />
            We strive to provide high-quality products and services that benefit
            both our clients and the environment.
          </p>
        </div>
        <div className="column">
          <img src={img3} alt="A person solving a rubiks cube" />
        </div>
      </div>
    </div>
  );
};

export default About;
