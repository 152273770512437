
const Product = ({name,image,link,desc}) => {
  
  return (  
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">{name}</h3> 
        </div>
        <div className="card-image">
          <img className="img" src= {image} alt={name}/> 
        </div>
        <div className="pdesc">
          <p className="desc">
            {desc}
          </p>
        </div>
        <div className='card-content'>
          <a href={link}>
            <div className='card-btn'>
              <h4>See more</h4>
            </div>
          </a>
        </div>
      </div>
  );
}
 
export default Product;