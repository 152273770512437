import Img1 from '../../Images/Solar_roof1.jpg';
import Img2 from '../../Images/Solar_roof2.jpg';
import Img3 from '../../Images/Solar_roof3.jpg';

const Solar_roof = () => {
  return ( 
    <div className='Product_page'>
      <h1>Solar rooftop</h1>
      <img src={Img3} className='Product_Hero_item' alt = 'Solar pannels on a rooftop'/>
      
      <div className='row' >
        <div className='column'>
          <h3>About rooftop solar</h3>
          <p className='Centered_text'>
            Our rooftop solar power system provides electricity-generating solar
            panels mounted on the roof of a residential or commercial building or
            structure. This system is an environmentally friendly and cost-effective
            way to generate electricity, as it harnesses the power of the sun to 
            produce energy. It also helps reduce reliance on traditional energy 
            sources and lower electricity bills.
          </p>
        </div>
        <div className='column'>
          <img src={Img2} alt='Someone installing solar pannels on a roof'/>
        </div>
      </div>

      <div className='row' >
        <div className='column'>
          <img src={Img1} alt='A building with solar pannels on the roof'/>
        </div>
        <div className='column'>
          <h3>Our approach</h3>
          <p>
            We are a team of experienced professionals dedicated to providing
            sustainable energy solutions for homes and businesses. Our approach
            involves customized designs and installations using the latest 
            technology in solar power to ensure maximum efficiency and cost
            savings for our clients.
            
          </p>
        </div>
      </div>


    </div>
  );
}
 
export default Solar_roof;