import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { fetchBlog } from "../redux/axios/blogs";
import { useParams } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

function BlogDetail() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [blog, setBlog] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const data = await dispatch(fetchBlog(id));
      setBlog(data.payload);
    };
    fetchData();
  }, [dispatch, id]);
  return (
    <div className="blogbody">
      <h2>
        {blog.title} <br />{" "}
        <span className="writter">
          | By {blog.author_first_name} {blog.author_last_name} |{" "}
          {blog.author_username}
        </span>
      </h2>

      <p>{ReactHtmlParser(blog.content)}</p>
    </div>
  );
}

export default BlogDetail;
