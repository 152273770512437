import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { Component } from "react";
import Slider from "react-slick";
import Service from "../Assets/Service element";
import Advisory from "../Images/business-to-consumer-plan.svg";
import Consultation from "../Images/contact-us-customer-support-chat.svg";
import Design from "../Images/design-draw.svg";
import Maintenence from "../Images/maintenance-tool.svg";
import Performance from "../Images/performance-increase-2.svg";

export default class OurServices extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 1000,
      className: "center",
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="holder-component">
        <h2>Our Services</h2>
        <Slider {...settings}>
          <Service title="Advisory services" image={Advisory} />
          <Service title="Free consultation" image={Consultation} />
          <Service title="Personal design" image={Design} />
          <Service title="System maintenence" image={Maintenence} />
          <Service title="Performance monitoring" image={Performance} />
        </Slider>
      </div>
    );
  }
}
