import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getBlogs,
  deleteBlog,
  removeBlog,
  fetchprofile,
} from "../redux/axios/blogs";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import noBlog from "../Images/blog-construction.jpg";
import ReactHtmlParser from "react-html-parser";

const Blog = (props) => {
  const [isDeleted, setIsDeleted] = useState(false); // New state for tracking deletion
  const blogslist = useSelector((state) => state.blogs);
  const profile = useSelector((state) => state.profile);

  const blogs = blogslist.reverse();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 2; // Change this to your desired number of items per page
  const totalItems = blogs.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = blogs.slice(startIndex, endIndex).reverse(); // Reverse the order of paginatedData

  const next = ">";
  const prev = "<";

  useEffect(() => {
    if (blogs.length === 0 || isDeleted) {
      dispatch(getBlogs());
      setIsDeleted(false);
    }
  }, [dispatch, blogs.length, isDeleted]);

  useEffect(() => {
    dispatch(fetchprofile());
  }, [dispatch, profile.length]);

  const options = { year: "numeric", month: "long", day: "numeric" };
  const authToken = Cookies.get("ac-tok-en");

  const goToPreviousPage = () => {
    setCurrentPage((currentPage) => currentPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage((currentPage) => currentPage + 1);
  };

  const handleDelete = async (e) => {
    const deletedId = e.target.id;
    try {
      await dispatch(deleteBlog(deletedId));
      dispatch(removeBlog(deletedId));
      setIsDeleted(true);
    } catch (error) {}
  };

  const handleSeeMore = (id) => {
    navigate(`/detail/${id}`);
  };

  const handleLogout = () => {
    sessionStorage.removeItem("role");
    Cookies.remove("ac-tok-en");
  };

  const handleEdit = (id) => {
    navigate(`/edit/${id}`);
  };

  return (
    <div className="blogs_cont">
      <div className="blogs">
        {blogs.length ? (
          paginatedData.map((blog) => (
            <div key={blog.id} className="singleblog">
              <div className="blog-cont">
                <div className="content-div">
                  <div className="profile-image">
                    <img src={blog.author_picture} alt={blog.author_usernam} />
                    <p>
                      Author: {blog.author_first_name} {blog.author_last_name} {blog.author_username}
                    </p>
                  </div>
                  <div className="content-blog">
                    <h2>{blog.title}</h2>
                    {ReactHtmlParser(blog.content.slice(0, 100))}...
                    <small>
                      {" "}
                      Published on{" "}
                      {new Date(blog.created_at).toLocaleDateString(
                        "en-US",
                        options
                      )}
                    </small>
                  </div>
                  {authToken &&
                  blog.author_first_name === profile.first_name ? (
                    <div className="edits">
                      <small
                        id={blog.id}
                        onClick={handleDelete}
                        className="delete"
                      >
                        delete
                      </small>
                      <small
                        id={blog.id}
                        className="edit"
                        onClick={(e) => handleEdit(e.target.id)}
                      >
                        edit
                      </small>
                      <small
                        id={blog.id}
                        className="readmore"
                        onClick={(e) => handleSeeMore(e.target.id)}
                      >
                        read more....
                      </small>
                    </div>
                  ) : (
                    <div>
                      <small
                        id={blog.id}
                        className="readmore"
                        onClick={(e) => handleSeeMore(e.target.id)}
                      >
                        read more....
                      </small>
                    </div>
                  )}
                </div>
                <div className="image-div">
                  <img src={blog.image} alt="" />
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="noblogs">
            {authToken ? (
              <Link to={"/createBlog"}>
                <small className="addbtn">Add a blog</small>
              </Link>
            ) : (
              <div>
                <img src={noBlog} alt="no blog" />
              </div>
            )}
          </div>
        )}

        {blogs.length ? (
          <div className="control">
            {/* Disable previous button if on the first page */}
            <button
              className="prev"
              disabled={currentPage === 1}
              onClick={goToPreviousPage}
            >
              {prev}
            </button>
            <span className="page">{currentPage}</span>
            {/* Disable next button if on the last page */}
            <button
              className="prev"
              disabled={currentPage === totalPages}
              onClick={goToNextPage}
            >
              {next}
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
      {authToken ? (
        <div className="account">
          <div className="profile-image">
            <img
              src={profile.profilepic}
              alt=""
            />
          </div>
          <div>
            <h3>
              {profile.first_name} {profile.last_name}
            </h3>
            <p>
              Editor: <span>{profile.email}</span>
            </p>
            <p>{profile.username}</p>
            <div className="buttons">
              {profile.is_writer ? (
                <Link to={"/createBlog"}>
                  <button className="addbtn">Add a blog</button>
                </Link>
              ) : (
                ""
              )}
              <button onClick={handleLogout} className="addbtn">
                log out
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Blog;
