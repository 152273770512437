import React from "react";
import Email from "../Images/Icons/email_black_24dp.svg";
import Location from "../Images/Icons/location_on_black_24dp.svg";
import Phone from "../Images/Icons/phone_black_24dp.svg";
import Linkedin from "../Images/Linkedin-logo.png";
import  "./contact.css"
function Contactus() {
  return (
    // <div id="Contacts_comp">
    //   <h1>Contact Us at</h1>
    //   <ul className="contact_ul">
    //     <li>
    //       <img src={Location} alt="Location symbol"></img>
    //       <p> No 29, 204 Road, 2nd Avenue, Festac Town, Lagos</p>
    //     </li>
    //     <li>
    //       <img src={Email} alt="Email symbol"></img>
    //       <p>katexpower@gmail.com, info@katexsolar.com </p>
    //     </li>
    //     <li>
    //       <img src={Phone} alt="Phone symbol"></img>
    //       <p>+2347060961849, <br></br>+2348158083403</p>
    //     </li>
    //     <li>
    //       <img src={Linkedin} alt="Linkedin logo" />
    //       <a
    //         className="linkedin"
    //         href="https://www.linkedin.com/company/katexpower/"
    //         target="_blank"
    //         rel="noopener noreferrer"
    //       >
    //         <p className="Deco_link">Linkedin.com/KatexPower</p>
    //       </a>
    //     </li>
    //   </ul>
    // </div>
    <div class="container">
      <div class="forms-container">
        <div class="signin-signup">
          <form action="#" class="sign-in-form">
            <h2 class="title">Send us a message</h2>
            <div class="input-field">
              <i class="fas fa-user"></i>
              <input type="text" placeholder="Username" />
            </div>
            <div class="input-field">
              <i class="fas fa-lock"></i>
              <input type="password" placeholder="Password" />
            </div>
            <input type="submit" value="Login" class="btn solid" />
            <p class="social-text">Or Sign in with social platforms</p>
            <div class="social-media">
              <a href="#" class="social-icon">
                <i class="fab fa-facebook-f"></i>
              </a>
              <a href="#" class="social-icon">
                <i class="fab fa-twitter"></i>
              </a>
              <a href="#" class="social-icon">
                <i class="fab fa-google"></i>
              </a>
              <a href="#" class="social-icon">
                <i class="fab fa-linkedin-in"></i>
              </a>
            </div>
          </form>
          <form action="#" class="sign-up-form">
            <h2 class="title">Sign up</h2>
            <div class="input-field">
              <i class="fas fa-user"></i>
              <input type="text" placeholder="Username" />
            </div>
            <div class="input-field">
              <i class="fas fa-envelope"></i>
              <input type="email" placeholder="Email" />
            </div>
            <div class="input-field">
              <i class="fas fa-lock"></i>
              <input type="password" placeholder="Password" />
            </div>
            <input type="submit" class="btn" value="Sign up" />
            <p class="social-text">Or Sign up with social platforms</p>
            <div class="social-media">
              <a href="#" class="social-icon">
                <i class="fab fa-facebook-f"></i>
              </a>
              <a href="#" class="social-icon">
                <i class="fab fa-twitter"></i>
              </a>
              <a href="#" class="social-icon">
                <i class="fab fa-google"></i>
              </a>
              <a href="#" class="social-icon">
                <i class="fab fa-linkedin-in"></i>
              </a>
            </div>
          </form>
        </div>
      </div>

      <div class="panels-container">
        <div class="panel left-panel">
          <div class="content">
            <h3>New here ?</h3>
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Debitis,
              ex ratione. Aliquid!
            </p>
            <button class="btn transparent" id="sign-up-btn">
              Sign up
            </button>
          </div>
          <img src="img/log.svg" class="image" alt="" />
        </div>
        <div class="panel right-panel">
          <div class="content">
            <h3>One of us ?</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum
              laboriosam ad deleniti.
            </p>
            <button class="btn transparent" id="sign-in-btn">
              Sign in
            </button>
          </div>
          <img src="img/register.svg" class="image" alt="" />
        </div>
      </div>
    </div>
  );
}

export default Contactus;
