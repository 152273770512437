import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SunEditor from "suneditor-react";
import { fetchBlog } from "../redux/axios/blogs"; // Import the updateBlog function
import "suneditor/dist/css/suneditor.min.css";
import { useDispatch } from "react-redux";
import { edditBlog } from "../redux/axios/blogs";

const defaultFonts = [
  "Arial",
  "Comic Sans MS",
  "Courier New",
  "Impact",
  "Georgia",
  "Tahoma",
  "Trebuchet MS",
  "Verdana",
];

export default function App() {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [image, setImage] = useState(null);
  const [content, setContent] = useState("");
  const [isEdited, setEdited] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const data = await dispatch(fetchBlog(id));
      setTitle(data.payload.title);
      setImage(data.payload.image);
      setContent(data.payload.content);
    };
    fetchData();
    setEdited(false);
  }, [dispatch, id, isEdited]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const blog = {
      id: id || null,
      title,
      image,
      content,
    };

    console.log(blog);
    // If ID is available, it's an update operation
    dispatch(edditBlog(blog));
    setEdited(true);
    navigate("/blog");
  };

  const sortedFontOptions = [
    "Logical",
    "Salesforce Sans",
    "Garamond",
    "Sans-Serif",
    "Serif",
    "Times New Roman",
    "Helvetica",
    ...defaultFonts,
  ].sort();

  return (
    <form
      className="App"
      id="wewe"
      onSubmit={handleSubmit}
      enctype="multipart/form-data"
    >
      <div className="inputdiv">
        <input
          placeholder="input Title and not too long "
          type="text"
          id="titlei-input"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <input
          placeholder="please add an image for your blog"
          type="file"
          alt="image"
          id="image"
          onChange={(e) => setImage(e.target.files[0])} // Update the image state with the selected file
        />
      </div>
      <SunEditor
        placeholder="write your blog here  and please dont start with an immage"
        setContents={content}
        onChange={setContent}
        setOptions={{
          buttonList: [
            ["undo", "redo"],
            ["font", "fontSize"],
            ["paragraphStyle", "blockquote"],
            [
              "bold",
              "underline",
              "italic",
              "strike",
              "subscript",
              "superscript",
            ],
            ["fontColor", "hiliteColor"],
            ["align", "list", "lineHeight"],
            ["outdent", "indent"],

            ["table", "horizontalRule", "link", "image", "video"],
            // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
            // ['imageGallery'], // You must add the "imageGalleryUrl".
            ["fullScreen", "showBlocks", "codeView"],
            ["preview", "print"],
            ["removeFormat"],

            ["save", "template"],
            // '/', Line break
          ],
          defaultTag: "div",
          minHeight: "500px",
          showPathLabel: false,
          font: sortedFontOptions,
        }}
      />
      <button type="submit" className="blogbtn">
        Post
      </button>
    </form>
  );
}
