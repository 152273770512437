import Solar_home_img from "../Images/Katexsolar.png";
import Solar_roof_img from "../Images/Solar_roof3.jpg";
import Mini_grid_img from "../Images/Mini_grid1.jpg";

import Product from "../Assets/Product_element";

const Products = () => {
  return (
    <div className="products-div">
      <h1>Our Products</h1>
      <p>We offer a range of solar power products</p>

      <div className="cards">
        <Product
          name="Solar home system"
          image={Solar_home_img}
          alt="Our solar home system"
          link="/Products/Solar_home_system"
          desc="This category of our Solar Solution product caters for energy between the capacity of 100W to 250W. They are compact systems built for lightning and powering small lifestyle................"
        />
        <Product
          name="Solar roof top system"
          image={Solar_roof_img}
          alt="Solar pannels on a roof top"
          link="/Products/Solar_roof"
          desc="Our rooftop solar power system provides electricity-generating solar panels mounted on the roof of a residential or commercial building or structure....................."
        />
        <Product
          name="Mini-grid"
          image={Mini_grid_img}
          alt="A solar powered mini-grid"
          link="/Products/Mini_grid"
          desc="We provide mini-grid services that offer sustainable and reliable energy solutions to communities without access to electricity. Our compact design and.........................."
        />
      </div>
    </div>
  );
};

export default Products;
