import { configureStore, combineReducers } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import {
  blogReducer,
  AddBlogReducer,
  profileReducer,
  profilesReducer,
} from "./reducers/blog_reducer";
import blogsReducer from "./reducers/blog_reducer";

const rootReducer = combineReducers({
  blogs: blogsReducer,
  addBog: AddBlogReducer,
  blog: blogReducer,
  profile: profileReducer,
  profiles: profilesReducer,
});
const store = configureStore({ reducer: rootReducer, middleware: [thunk] });

export default store;
