import Img1 from '../../Images/Mini_grid1.jpg';
import Img2 from '../../Images/Mini_grid2.jpg';
import Img3 from '../../Images/Mini_grid3.jpg';

const Mini_grid = () => {
  return (  
    <div className='Product_page'>
      <h1>Mini grid</h1>
      <img src={Img1} className='Product_Hero_item' alt = 'A village solar project'/>

      <div className='row'>
        <div className='column'>
          <h3>About mini grid</h3>
          <p className='Centered_text'>
            We provide mini-grid services that offer sustainable and reliable energy
            solutions to communities without access to electricity. Our compact 
            design and user-friendly installation process can improve the quality 
            of life for people living in remote areas, while also decreasing their 
            reliance on fossil fuels.
          </p>
        </div>
        <div className='column'>
          <img src={Img2} alt='A rural solar power setup'/>
        </div>
      </div>

      <div className='row'>
        <div className='column'>
          <img src={Img3} alt='A large solar power plant'/>
        </div>
        <div className='column'>
          <h3>Our technology</h3>
          <p>
            M2M Technology. Our machine-to-machine (M2M) technologies enables
            low-income households to access solar power products through a 
            ‘pay-as-you-go’ (PAYGO) instalment plan
            <br/><br/>
            Smart Energy Management. The system is embedded with GSM technology
            for monitoring and metering usage and enables us to troubleshoot 
            remotely.
            <br/><br/>
            Reliable Lithium-Iron-Phosphate Battery. Has a significantly longer
            lifetime compared to Lithium NMC and is safer to use
          </p>
        </div>
      </div>

    </div>
  );
}
 
export default Mini_grid;