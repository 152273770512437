import cchub from "../Images/Partner logos/CCHUB-logo.png";
import cleantech from "../Images/Partner logos/clean-tech.jpg";
import cocreation from "../Images/Partner logos/co-creation-hub.png";
import fate from "../Images/Partner logos/fate-foundation.png";
import fosera from "../Images/Partner logos/fosera.png";
import jinko from "../Images/Partner logos/jinko.png";
import powerafrica from "../Images/Partner logos/power-africa.png";
import rean from "../Images/Partner logos/rean-logo.jpg";
import weforgood from "../Images/Partner logos/weforgood.jpeg";
import wemakechange from "../Images/Partner logos/wemakechange.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { Component } from "react";
import Slider from "react-slick";

export default class AutoPlay extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 2000,
      className: "center",
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="holder">
        <h2>Our Partners</h2>
        <Slider {...settings}>
          <div className="carousel-cont">
            <div className="company-title">
              <h3>we for good</h3>
            </div>
            <div className="company-immage">
              <img src={weforgood} alt="" />
            </div>
            <div className="company-desc">
              <p className="company-desc-p">
                A sustainable solutions and impact design firm bringing people
                together on important causes
              </p>
              <a href="https://weforgood.org/'">site</a>
            </div>
          </div>
          <div className="carousel-cont">
            <div className="company-title">
              <h3> cc hub</h3>
            </div>
            <div className="company-immage">
              <img src={cchub} alt="" />
            </div>
            <div className="company-desc">
              <p className="company-desc-p">
                A sustainable solutions and impact design firm bringing people
                together on important causes
              </p>
              <a href="https://weforgood.org/'">site</a>
            </div>
          </div>
          <div className="carousel-cont">
            <div className="company-title">
              <h3>clean tech</h3>
            </div>
            <div className="company-immage">
              <img src={cleantech} alt="" />
            </div>
            <div className="company-desc">
              <p className="company-desc-p">
                A sustainable solutions and impact design firm bringing people
                together on important causes
              </p>
              <a href="https://weforgood.org/'">site</a>
            </div>
          </div>
          <div className="carousel-cont">
            <div className="company-title">
              <h3>co creation</h3>
            </div>
            <div className="company-immage">
              <img src={cocreation} alt="" />
            </div>
            <div className="company-desc">
              <p className="company-desc-p">
                A sustainable solutions and impact design firm bringing people
                together on important causes
              </p>
              <a href="https://weforgood.org/'">site</a>
            </div>
          </div>
          <div className="carousel-cont">
            <div className="company-title">
              <h3>fate</h3>
            </div>
            <div className="company-immage">
              <img src={fate} alt="" />
            </div>
            <div className="company-desc">
              <p className="company-desc-p">
                A sustainable solutions and impact design firm bringing people
                together on important causes
              </p>
              <a href="https://weforgood.org/'">site</a>
            </div>
          </div>
          <div className="carousel-cont">
            <div className="company-title">
              <h3>fosera</h3>
            </div>
            <div className="company-immage">
              <img src={fosera} alt="" />
            </div>
            <div className="company-desc">
              <p className="company-desc-p">
                A sustainable solutions and impact design firm bringing people
                together on important causes
              </p>
              <a href="https://weforgood.org/'">site</a>
            </div>
          </div>
          <div className="carousel-cont">
            <div className="company-title">
              <h3>jinko</h3>
            </div>
            <div className="company-immage">
              <img src={jinko} alt="" />
            </div>
            <div className="company-desc">
              <p className="company-desc-p">
                A sustainable solutions and impact design firm bringing people
                together on important causes
              </p>
              <a href="https://weforgood.org/'">site</a>
            </div>
          </div>
          <div className="carousel-cont">
            <div className="company-title">
              <h3>power africa</h3>
            </div>
            <div className="company-immage">
              <img src={powerafrica} alt="" />
            </div>
            <div className="company-desc">
              <p className="company-desc-p">
                A sustainable solutions and impact design firm bringing people
                together on important causes
              </p>
              <a href="https://weforgood.org/'">site</a>
            </div>
          </div>
          <div className="carousel-cont">
            <div className="company-title">
              <h3>rean</h3>
            </div>
            <div className="company-immage">
              <img src={rean} alt="" />
            </div>
            <div className="company-desc">
              <p className="company-desc-p">
                A sustainable solutions and impact design firm bringing people
                together on important causes
              </p>
              <a href="https://weforgood.org/'">site</a>
            </div>
          </div>
          <div className="carousel-cont">
            <div className="company-title">
              <h3>we make change</h3>
            </div>
            <div className="company-immage">
              <img src={wemakechange} alt="" />
            </div>
            <div className="company-desc">
              <p className="company-desc-p">
                A sustainable solutions and impact design firm bringing people
                together on important causes
              </p>
              <a href="https://weforgood.org/'">site</a>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}
