import React, { Component } from "react";
import Slider from "react-slick";

export default class simpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 10000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 768, // iPad breakpoint
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    
    return (
      <Slider {...settings}>
        <div className="sliderdiv">
          <div className="div1">
            <h1>Welcome to KatexPower</h1>
            <p>
              Welcome to KatexPower, where we believe in unleashing the power of
              the sun to revolutionize energy access and create a brighter
              future for Africa. We are dedicated to transforming the way
              communities access and utilize energy, paving the way for a
              sustainable and prosperous nation.
            </p>
            <button>our products</button>
          </div>
          <div className="div2"></div>
        </div>
        <div className="sliderdiv">
          <div className="div7">
            <h1>innovative solar solutions</h1>
            <p>
              With our innovative solar solutions, we harness the limitless
              potential of the sun, providing clean and reliable energy to even
              the most remote and underserved areas. Through our cutting-edge
              technology and unwavering commitment, we are breaking down
              barriers and bridging the energy gap that has held back progress
              and limited opportunities.
            </p>
            <button>contact us</button>
          </div>
          <div className="div3"></div>
        </div>
        <div className="sliderdiv">
          <div className="div6">
            <h1>katex power </h1>
            <p>
              At KatexPower, we understand that energy is not just about
              powering appliances and devices; it is about empowering people. By
              bringing solar solutions to life, we empower individuals,
              families, and communities to thrive. We provide the key that
              unlocks the door to education, healthcare, entrepreneurship, and a
              better quality of life.
            </p>
            <button>Our blogs</button>
          </div>
          <div className="div4"></div>
        </div>
        <div className="sliderdiv">
          <div className="div1">
            <h1>Technology and comprehensive solutions</h1>
            <p>
              Through our cutting-edge solar technologies and comprehensive
              solutions, we are illuminating Nigeria's path to sustainability.
              We bring light to homes, schools, hospitals, and businesses,
              ensuring that no corner of the nation is left in the dark. {
                
              }
              possibilities.
            </p>
            <button>Services</button>
          </div>
          <div className="div5"></div>
        </div>
      </Slider>
    );
  }
}
