
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import logo from '../Images/Katex_power_logo.png';
import Cookies from 'js-cookie';
import { logout } from '../redux/axios/blogs';
import { useDispatch } from 'react-redux';



const Header = () => {
  const [click, setClick] = useState(false);
  const dispatch = useDispatch();

  const handleClick = () => setClick(!click);

  const handleLogout = () => {
    try {
      dispatch(logout());
      sessionStorage.removeItem('role');
      Cookies.remove('ac-tok-en');
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  const handleClose = () => setClick(false);
  const authToken = Cookies.get('ac-tok-en');
  const role = sessionStorage.getItem('role');

  return (
    <div>
      <div className={click ? "main-container" : ""} onClick={handleClose} />
      <nav className="navbar" onClick={e => e.stopPropagation()}>
        <div className="nav-container">
          <NavLink exact to="/" className="nav-logo">
            <img id='Header_logo' src = {logo} alt = 'Katex power logo'/>
          </NavLink>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/about"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                About
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/products"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Products
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/services"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                services
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/Partners"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Patners
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/blog"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Blog
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/contact"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Contact Us
              </NavLink>
            </li>
            {role && role.toString() === 'true' ?(
               <li className="nav-item">
               <NavLink
                 to="/admin"
                 activeClassName="active"
                 className="nav-links"
                 onClick={handleClick}
               >
                 Admin
               </NavLink>
             </li>
            ):(
              <div className='displaynone'>

              </div>
            )

            }
            {authToken?(
              <li className="nav-item">
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav-links"
                onClick={handleLogout}
              >
                Logout
              </NavLink>
            </li>
            ):(
              <li className="nav-item">
              <NavLink
                exact
                to="/login"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Login
              </NavLink>
            </li>
            )

            }
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            <FontAwesomeIcon icon={click ? faTimes : faBars} />
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
