import Demo_video from '../../Assets/Katex_power_vid.mp4';
import Img1 from '../../Images/Solar_home1.jpg';
import Img2 from '../../Images/Solar_home2.jpg';

const Solar_home_system = () => {
  return (  
    <div className='Product_page'>
      <h1>Solar home system</h1>
      <video controls src = {Demo_video} className='Product_Hero_item'/>
      <div>
        <div className='row'>
          <div className='column'>
            <img src={Img2} alt ='A happy family in their well-lit home'/>
          </div>
          <div className='column'>
            <h3>About solar home</h3>
            <p>
              This category of our Solar Solution product caters for energy
              between the capacity of 100W to 250W. They are compact systems 
              built for lightning and powering small lifestyle appliances like
              mobile phones, fans DC Bulbs and DC TVs. Designed for home use
              and for micro and small-scale business.
            </p>
          </div>
        </div>

        <div className='row'>
          <div className='column'>
            <h3>Our technology</h3>
            <p>
              We are a team of experienced professionals dedicated to providing 
              sustainable energy solutions for homes and businesses. Our 
              approach involves customized designs and installations using the 
              latest technology in solar power to ensure maximum efficiency and
               cost savings for our clients.
               <br/><br/>
              M2M Technology. Our machine-to-machine (M2M) technologies enables
              low-income households to access solar power products through a 
              ‘pay-as-you-go’ (PAYGO) instalment plan
              <br/><br/>
              Smart Energy Management. The system is embedded with GSM 
              technology for monitoring and metering usage and enables us to
              troubleshoot remotely.
              <br/><br/>
              Reliable Lithium-Iron-Phosphate Battery. Has a significantly 
              longer lifetime compared to Lithium NMC and is safer to use
            </p>
          </div>
          <div className='column'>
            <img src={Img1} alt='An example of our solar home system'/>
          </div>
        </div>

      </div>
    </div>
  );
}
 
export default Solar_home_system;