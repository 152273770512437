import Advisory from "../Images/business-to-consumer-plan.svg";
import Consultation from "../Images/contact-us-customer-support-chat.svg";
import Design from "../Images/design-draw.svg";
import Maintenence from "../Images/maintenance-tool.svg";
import Performance from "../Images/performance-increase-2.svg";

import Service from "../Assets/Service element";

const Services = () => {
  //TODO: get final images/copy, maybe consider making dynamic(prob reqs talk to backend)
  return (
    <div>
      <h1>Our Services</h1>
      <h4>
        If you would like more information feel free to{" "}
        <a href="/Contact" className="Deco_link">
          Contact us
        </a>
      </h4>
      <div id="Services_container">
        <Service
          title="Advisory services"
          image={Advisory}
          description="We provide management consulting in energy efficiency, decarbonization, energy transition and more."
        />
        <Service
          title="Free consultation"
          image={Consultation}
          description="Our professional solar and energy solution experts are happy to answer any queries you have."
        />
        <Service
          title="Personal design"
          image={Design}
          description="Get a personalised solar design according to your requirements"
        />
        <Service title="System maintenence" image={Maintenence} />
        <Service title="Performance monitoring" image={Performance} />
      </div>
    </div>
  );
};

export default Services;
