import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  FETCHBLOGS,
  ADDBLOG,
  FETCH_BLOG,
  FETCH_PROFILE,
  FETCH_PROFILES,
  UPDATE_PROFILES,
  DELETE_PROFILES,
  BASEURL,
  EDDITBLOG,
  REMOVEBLOG,
  LOGOUT,
} from "../constants/constants";
import axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";

export const getBlogs = createAsyncThunk(FETCHBLOGS, async () => {
  const data = await fetch(`${BASEURL}/posts/`);
  const response = await data.json();
  console.log(response);
  return response || [];
});

export const addBlog = createAsyncThunk(ADDBLOG, async (blog) => {
  const encodedToken = Cookies.get("ac-tok-en");
  const key = "accesstoken";
  const bytes = CryptoJS.AES.decrypt(encodedToken, key);
  const decryptedAccessToken = bytes.toString(CryptoJS.enc.Utf8);
  const response = await axios.post(`${BASEURL}/posts/`, blog, {
    withCredentials: true,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${decryptedAccessToken}`,
    },
  });
  return response.data;
});

export const deleteBlog = createAsyncThunk(REMOVEBLOG, async (id) => {
  const encodedToken = Cookies.get("ac-tok-en");
  const key = "accesstoken";
  const bytes = CryptoJS.AES.decrypt(encodedToken, key);
  const decryptedAccessToken = bytes.toString(CryptoJS.enc.Utf8);
  const response = await axios.delete(`${BASEURL}/posts/${id}/delete/`, {
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${decryptedAccessToken}`,
    },
  });
  return response.data;
});

export const fetchBlog = createAsyncThunk(FETCH_BLOG, async (id) => {
  const response = await axios.get(`${BASEURL}/posts/${id}/`);
  return response.data;
});

export const edditBlog = createAsyncThunk(EDDITBLOG, async (blog) => {
  const encodedToken = Cookies.get("ac-tok-en");
  const key = "accesstoken";
  const bytes = CryptoJS.AES.decrypt(encodedToken, key);
  const decryptedAccessToken = bytes.toString(CryptoJS.enc.Utf8);
  console.log(blog);
  const response = await axios.patch(
    `${BASEURL}/posts/${blog.id}/`,
    {
      title: blog.title,
      content: blog.content,
      image: blog.image,
    },
    {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${decryptedAccessToken}`,
      },
    }
  );
  return response.data;
});

export const removeBlog = (id) => ({
  type: REMOVEBLOG,
  payload: id,
});

export const fetchProfiles = createAsyncThunk(FETCH_PROFILES, async () => {
  const encodedToken = Cookies.get("ac-tok-en");
  const key = "accesstoken";
  const bytes = CryptoJS.AES.decrypt(encodedToken, key);
  const decryptedAccessToken = bytes.toString(CryptoJS.enc.Utf8);
  const response = await axios.get(`${BASEURL}/user-profiles/`, {
    headers: {
      Authorization: `Bearer ${decryptedAccessToken}`,
      "Is-Admin": false,
    },
  });
  return response.data;
});

export const updateProfile = createAsyncThunk(UPDATE_PROFILES, async (data) => {
  const encodedToken = Cookies.get("ac-tok-en");
  const key = "accesstoken";
  const bytes = CryptoJS.AES.decrypt(encodedToken, key);
  const decryptedAccessToken = bytes.toString(CryptoJS.enc.Utf8);
  const response = await axios.patch(
    `${BASEURL}/profile/${data.id}/`,
    data.is_writer,
    {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${decryptedAccessToken}`,
      },
    }
  );
  return response.data;
});

export const deleteProfile = createAsyncThunk(DELETE_PROFILES, async (id) => {
  const encodedToken = Cookies.get("ac-tok-en");
  const key = "accesstoken";
  const bytes = CryptoJS.AES.decrypt(encodedToken, key);
  const decryptedAccessToken = bytes.toString(CryptoJS.enc.Utf8);
  const response = await axios.delete(`${BASEURL}/profile/${id}/`, {
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${decryptedAccessToken}`,
    },
  });
  return response.data;
});

export const fetchprofile = createAsyncThunk(FETCH_PROFILE, async () => {
  const encodedId = Cookies.get("userId");
  const keyId = "user_id";
  const bytesId = CryptoJS.AES.decrypt(encodedId, keyId);
  const decryptedId = bytesId.toString(CryptoJS.enc.Utf8);
  const encodedToken = Cookies.get("ac-tok-en");
  const key = "accesstoken";
  const bytes = CryptoJS.AES.decrypt(encodedToken, key);
  const decryptedAccessToken = bytes.toString(CryptoJS.enc.Utf8);
  const response = await axios.get(`${BASEURL}/profile/${decryptedId}`, {
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${decryptedAccessToken}`,
    },
  });
  return response.data;
});


export const logout = createAsyncThunk(LOGOUT, async () => {
  const encodedToken = Cookies.get("ref-tok-en");
  const key = "accesstoken";
  const bytes = CryptoJS.AES.decrypt(encodedToken, key);
  const decryptedAccessToken = bytes.toString(CryptoJS.enc.Utf8);
  const response = await axios.post(`${BASEURL}/posts/`, {
    withCredentials: true,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${decryptedAccessToken}`,
    },
  });
  return response.data;
});