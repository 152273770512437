import React from "react";
import Products from "./Products";
import Contactus from "../components/Contact_us";
import Line from "../components/Line";
import AboutUs from "../components/About";
import OurServices from "../components/OurServices";
import SimplSlider from "../components/Slider";
import Footer from "../Assets/Footer";

const Home = () => {
  return (
    <div>
      <div className="siderholder">
        <SimplSlider />
      </div>
      <div className="about">
        <AboutUs />
      </div>
      <Line />
      <Products />
      <Line />
      <OurServices />
      <Line />
      <Contactus />
      <Footer />
    </div>
  );
};

export default Home;
