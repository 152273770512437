import React from "react";
import img1 from "../Images/aboutimage.jpg";
import { Link } from "react-router-dom";

function AboutUs() {
  return (
    <div className="aboutdiv">
      <h1>About us</h1>
      <div className="row">
        <div className="column" id="aboutimage">
          <img src={img1} alt="A group of children smiling in a window" />
        </div>
        <div className="column">
          <p>
            KatexPower is a renewable energy company that offers clean and
            sustainable energy services to off-grid and underserved communities.
            <br />
            <br />
            Our mission is to provide clean, affordable and reliable energy
            solutions that improve the quality of life for individuals and
            communities while reducing carbon emissions.
            <br />
            <br />
            We aim to empower people through access to clean energy, creating a
            more sustainable future for all. With years of experience in the
            industry, we have a proven track record of delivering high-quality
            solar installations and exceptional customer service.
          </p>
          <Link className="aboutlink" to={"/about"}>
            Read more
          </Link>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
