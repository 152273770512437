
const Service = ({title, image, description =''}) => {
    return ( 
        <div className="Service_item">
          <div className="Service_img_background">
            <img src = {image} alt = {title}/>
          </div>
        <h2 id="servicetitle">{title}</h2>
        <p>{description}</p>
      </div>
     );
}
 
export default Service;