import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SunEditor from "suneditor-react";
import { useDispatch } from "react-redux";
import { addBlog, edditBlog } from "../redux/axios/blogs";
import { fetchBlog } from "../redux/axios/blogs";
import "suneditor/dist/css/suneditor.min.css";

const defaultFonts = [
  "Arial",
  "Comic Sans MS",
  "Courier New",
  "Impact",
  "Georgia",
  "Tahoma",
  "Trebuchet MS",
  "Verdana",
];

export default function App() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [blogToUpdate, setBlogToUpdate] = useState(null);
   // Assuming you have a state variable to track the blog to be updated

  const [title, setTitle] = useState(blogToUpdate?.title || ""); // Initialize title with the existing blog's title if available
  const [image, setImage] = useState(blogToUpdate?.image || ""); // Initialize image with the existing blog's image if available
  const [content, setContent] = useState(blogToUpdate?.content || ""); // Initialize content with the existing blog's content if available


  useEffect(() => {
    if (id) {
      // Fetch the blog to be updated if an ID is provided
      dispatch(fetchBlog(id));
      dispatch(fetchBlog(id)).then((data) => {
        setBlogToUpdate(data.payload);
      });
    }
  }, [dispatch, id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const blog = {
      id: id || null, // Provide the ID if available
      title,
      image,
      content,
    };

    if (id) {
      // If ID is available, it's an update operation
      dispatch(edditBlog(blog));
    } else {
      // Otherwise, it's a new blog creation
      dispatch(addBlog(blog));
    }

    setContent("");
    setTitle("");
    navigate("/blog");
  };

  const sortedFontOptions = [
    "Logical",
    "Salesforce Sans",
    "Garamond",
    "Sans-Serif",
    "Serif",
    "Times New Roman",
    "Helvetica",
    ...defaultFonts,
  ].sort();

  return (
    <form className="App" id="wewe" onSubmit={handleSubmit} enctype="multipart/form-data">
      <div className="inputdiv">
      <input
        placeholder="input Title and not too long "
        type="text"
        id="titlei-input"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <input
        placeholder="please add an image for your blog"
        type="file"
        alt="image"
        id="image"
        onChange={(e) => setImage(e.target.files[0])} // Update the image state with the selected file
      />
      </div>
      <SunEditor
        placeholder="write your blog here  and please dont start with an immage"
        setContents={content}
        onChange={setContent}
        setOptions={{
          buttonList: [
            ["undo", "redo"],
            ["font", "fontSize"],
            ['paragraphStyle', 'blockquote'],
            [
              "bold",
              "underline",
              "italic",
              "strike",
              "subscript",
              "superscript"
            ],
            ["fontColor", "hiliteColor"],
            ["align", "list", "lineHeight"],
            ["outdent", "indent"],

            ["table", "horizontalRule", "link", "image", "video"],
            // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
            // ['imageGallery'], // You must add the "imageGalleryUrl".
            ["fullScreen", "showBlocks", "codeView"],
            ["preview", "print"],
            ["removeFormat"],

            ['save', 'template'],
            // '/', Line break
          ],
          defaultTag: "div",
          minHeight: "500px",
          showPathLabel: false,
          font: sortedFontOptions,
        }}
      />
      <button type="submit" className="blogbtn">Post</button>
    </form>
  );
}
