import {
  FETCHBLOGS,
  ADDBLOG,
  REMOVEBLOG,
  DELETE_BLOG,
  FETCH_BLOG,
  EDDITBLOG,
  FETCH_PROFILE,
  FETCH_PROFILES,
} from "../constants/constants";
const initialState = [];
const blogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCHBLOGS}/fulfilled`:
      return action.payload;
    case `${REMOVEBLOG}fulfilled`: {
      return state.filter((state) => state.id !== action.payload);
    }
    case DELETE_BLOG:
      // No need to update the state here, just return it as is
      return state;
    default:
      return state;
  }
};

export default blogsReducer;

export const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_PROFILE}/fulfilled`:
      return action.payload;
    default:
      return state;
  }
};

export const profilesReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_PROFILES}/fulfilled`:
      return action.payload;
    default:
      return state;
  }
};

export const AddBlogReducer = (state = {}, action) => {
  switch (action.type) {
    case `${ADDBLOG}/fulfilled`: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export const blogReducer = (state = {}, action) => {
  switch (action.type) {
    case `${FETCH_BLOG}fulfilled`: {
      return { ...state, ...action.payload };
    }
    case `${EDDITBLOG}fulfilled`: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};
