import { Link } from "react-router-dom";
const NotFound = () => {
  return (
    <div className="NotFound">
      <h2>Sorry</h2>
      <p>This page cannot be found</p>
      <p>If there should be something here, please let us know</p>
      <Link to="/">Back to homepage</Link>
    </div>
  );
};

export default NotFound;
