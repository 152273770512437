import Email from "../Images/Icons/email_black_24dp.svg";
import Location from "../Images/Icons/location_on_black_24dp.svg";
import Phone from "../Images/Icons/phone_black_24dp.svg";
import Linkedin from "../Images/Linkedin-logo.png";

const Contact = () => {
  //TODO: get better intro
  return (
    <div id="Contacts">
      <h1>Contact Us at</h1>
      <ul>
        <li>
          <img src={Location} alt="Location symbol"></img>
          <p> No 29, 204 Road, 2nd Avenue, Festac Town, Lagos</p>
        </li>
        <li>
          <img src={Email} alt="Email symbol"></img>
          <p>katexpower@gmail.com, info@katexsolar.com </p>
        </li>
        <li>
          <img src={Phone} alt="Phone symbol"></img>
          <p>+2347060961849, +2347057561046</p>
        </li>
        <li>
          <img src={Linkedin} alt="Linkedin logo" />
          <a
            className="linkedin"
            href="https://www.linkedin.com/company/katexpower/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p className="Deco_link">Linkedin.com/KatexPower</p>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Contact;
