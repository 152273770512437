import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";

import Header from "./Assets/Header";
import Admin from "./components/Admin";
import Footer from "./Assets/Footer";
import About from "./Pages/About";
import CreateBlog from "./Pages/CreateBlog";
import Blog from "./Pages/Blog";
import Contact_us from "./Pages/Contact_us";
import Home from "./Pages/Home";
import NotFound from "./Pages/Not_found";
import Partners from "./Pages/Partners";
import Privacy from "./Pages/Privacy_policy";
import Products from "./Pages/Products";
import Services from "./Pages/Services";
import Terms_of_use from "./Pages/TOU";
import Solar_home_system from "./Pages/Product_pages/Solar_home_system";
import Solar_roof from "./Pages/Product_pages/Solar_roof";
import Mini_grid from "./Pages/Product_pages/Mini_grid";
import Login from "./Auth/Login";
import Register from "./Auth/Register";
import BlogDetail from "./components/BlogDetail";
import Edit from "./components/Edit";

function App() {
  document.title = "Katex power";
  return (
    <Router>
      <div className="App">
        <Header />
        <div className="Content">
          <Routes>
            <Route path="/login" Component={Login} />
            <Route path="/admin" Component={Admin} />
            <Route path="/edit/:id" Component={Edit} />
            <Route path="/register" Component={Register} />
            <Route exact path="/" Component={Home} />
            <Route path="/About" Component={About} />
            <Route path="/Blog" Component={Blog} />
            <Route path="/Contact" Component={Contact_us} />
            <Route path="/detail/:id" Component={BlogDetail} />
            <Route path="/Partners" Component={Partners} />
            <Route path="/Privacy" Component={Privacy} />
            <Route path="/Products" Component={Products} />
            <Route path="/Services" Component={Services} />
            <Route path="/TOU" Component={Terms_of_use} />
            <Route
              path="/Products/Solar_home_system"
              Component={Solar_home_system}
            />
            <Route path="/Products/Solar_roof" Component={Solar_roof} />
            <Route path="/Products/Mini_grid" Component={Mini_grid} />
            <Route path="*" Component={NotFound} />
            <Route path="/createBlog" Component={CreateBlog} />
          </Routes>
          {/* we want to protect these routes */}
        </div>

      </div>
    </Router>
  );
}

export default App;
