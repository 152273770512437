import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteProfile,
  updateProfile,
  fetchProfiles,
} from "../redux/axios/blogs";

function Admin() {
  const profiles = useSelector((state) => state.profiles);
  console.log(profiles);
  const dispatch = useDispatch();
  const role = sessionStorage.getItem("role");
  const [checkedRows, setCheckedRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    dispatch(fetchProfiles());
  }, [dispatch, profiles.length]);

  const handleCheckboxChange = async (e, rowIndex) => {
    const isChecked = e.target.checked;
    const id = e.target.id;
    let isWriterValue = isChecked ? true : false;

    if (!isChecked) {
      isWriterValue = false;
    }

    const data = {
      id: id,
      is_writer: {
        is_writer: isWriterValue,
      },
    };

    setCheckedRows((prevCheckedRows) => {
      if (isChecked) {
        return [...prevCheckedRows, rowIndex];
      } else {
        return prevCheckedRows.filter((index) => index !== rowIndex);
      }
    });
    try {
      dispatch(updateProfile(data));
    } catch (error) {
      dispatch(fetchProfiles());
    }
  };

  const handleDeleteClick = async (e) => {
    const id = e.target.id;
    try {
      await dispatch(deleteProfile(id));
      setCheckedRows((prevCheckedRows) =>
        prevCheckedRows.filter((index) => index !== id)
      );
      dispatch(fetchProfiles()); // Fetch updated profiles after deletion
      // Handle success or perform any additional actions
    } catch (error) {
      // Handle error or display error message
    }
  };

  const filteredProfiles = profiles.filter((profile) =>
    profile.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      {role && role.toString() === "true" ? (
        <div>
          <input
            className="search"
            type="text"
            placeholder="Search by username"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          <table>
            <thead>
              <tr>
                <th scope="col">Username</th>
                <th scope="col">First Name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Email</th>
                <th scope="col">Writer</th>
                <th scope="col">Admins</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              {filteredProfiles.map((profile, index) => {
                const isChecked = profile.is_writer
                  ? true
                  : checkedRows.includes(index);
                return (
                  <tr key={index}>
                    <td data-label="Account">
                      {profile.username ? profile.username : "no name"}
                    </td>
                    <td data-label="First Name">
                      {profile.first_name ? profile.first_name : "no firstName"}
                    </td>
                    <td data-label="Last Name">
                      {profile.last_name ? profile.last_name : "no lastName"}
                    </td>
                    <td data-label="Email">{profile.email}</td>
                    <td data-label="Select">
                      <input
                        id={profile.id}
                        type="checkbox"
                        checked={isChecked}
                        onChange={(e) => handleCheckboxChange(e, index)}
                      />
                    </td>
                    <td data-label="Admin">
                      <input
                        id={profile.id}
                        type="checkbox"
                        checked={profile.is_admin}
                      />
                    </td>
                    <td data-label="Select">
                      <button
                        id={profile.id}
                        onClick={(e) => handleDeleteClick(e)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <p>You are not authorized.</p>
      )}
    </div>
  );
}

export default Admin;
